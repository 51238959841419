import React from 'react'
import ReactTooltip from 'react-tooltip'
import { InformationCircleIcon } from '@heroicons/react/outline'

type InputElementProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
type CustomizableInputElementProps = Pick<
  InputElementProps,
  | 'autoComplete'
  | 'autoFocus'
  | 'defaultValue'
  | 'disabled'
  | 'id'
  | 'onBlur'
  | 'onChange'
  | 'onClick'
  | 'placeholder'
  | 'required'
  | 'type'
  | 'value'
>

type Props = {
  label: string
  tooltip?: string
} & CustomizableInputElementProps

const FormInput = React.forwardRef<HTMLInputElement, Props>(({ id, label, tooltip, ...props }, ref) => {
  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
        {tooltip && (
          <>
            <span>
              &nbsp;
              <InformationCircleIcon height="16px" className="inline" data-tip={tooltip} />
              <ReactTooltip place="top" type="info" effect="float" backgroundColor="#F56991" />
            </span>
          </>
        )}
      </label>
      <div className="mt-1 max-w-prose">
        <input
          id={id}
          name={id}
          ref={ref}
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
          {...props}
        />
      </div>
    </div>
  )
})

FormInput.displayName = 'FormInput'

export default FormInput
