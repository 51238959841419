import React, { useEffect, useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { UseFormSetValue } from 'react-hook-form'

import { AvatarDiamond, AvatarFacebook } from './index'
import { User, UserAvatar, UserProfile } from '../lib/types'
import { fetchAvatar } from '../lib/supabase'

function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(' ')
}

type Props = { label: string; user: User | null; profile?: UserProfile | null; setValue: UseFormSetValue<UserProfile> }

const FormAvatarSelector = React.forwardRef<HTMLInputElement, Props>(({ label, user, profile, setValue }, ref) => {
  const [fbAvatar, setFBAvatar] = useState<UserAvatar | null>(null)
  useEffect(() => {
    if (user) {
      async function fetchData() {
        let avatar = await fetchAvatar(user!)
        setFBAvatar(avatar)
      }
      fetchData()
    }
  }, [user])

  const [selectedAvatar, setSelectedAvatar] = useState<number | null>(profile?.avatar?.id ?? null)

  const handleSelectAvatar = (selection: number | null) => {
    setValue('avatarId', selection)
    setSelectedAvatar(selection)
  }

  return (
    <>
      <RadioGroup value={selectedAvatar} onChange={handleSelectAvatar}>
        <RadioGroup.Label htmlFor="avatar" className="block mb-2 text-sm font-medium text-gray-700">
          {label}
        </RadioGroup.Label>
        <div className="block flex w-prose focus:ring-pink-500 focus:border-pink-500 border-gray-300 rounded-md">
          <RadioGroup.Option value={null}>
            {({ checked }) => (
              <div
                className={classNames(
                  checked
                    ? 'border-pink-500 text-pink-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'flex justify-center items-center w-24 h-24 bg-gray-50 shadow rounded-sm text-center border-b-2 font-medium text-sm',
                )}
              >
                <AvatarDiamond admin={profile?.admin ?? false} size="lg" />
              </div>
            )}
          </RadioGroup.Option>
          <div className="mx-2" />
          <RadioGroup.Option value={fbAvatar?.id}>
            {({ checked }) => (
              <div
                className={classNames(
                  checked
                    ? 'border-pink-500 text-pink-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'flex justify-center items-center w-24 h-24 bg-gray-50 shadow rounded-sm text-center border-b-2 font-medium text-sm',
                )}
              >
                {fbAvatar && <AvatarFacebook avatar={fbAvatar} size="lg" />}
              </div>
            )}
          </RadioGroup.Option>
        </div>
      </RadioGroup>
      <input type="hidden" defaultValue={selectedAvatar ?? undefined} ref={ref} />
    </>
  )
})

FormAvatarSelector.displayName = 'FormAvatarSelector'

export default FormAvatarSelector
