import { AvatarDiamond, AvatarFacebook } from '.'
import { UserProfile } from '../lib/types'

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export type AvatarProps = { size: Size }

type Props = { profile: UserProfile | null } & AvatarProps

export default function Avatar({ profile, size }: Props) {
  if (profile?.avatar) {
    return <AvatarFacebook avatar={profile.avatar!} size={size} />
  }

  return <AvatarDiamond size={size} admin={profile?.admin || false} />
}

export function calculateSize(size: Size) {
  switch (size) {
    case 'md':
      return 40
    case 'lg':
      return 64
    default:
      throw new Error('Unsupported size. Please consult with design team.')
  }
}
