import Button from './Button'
import Avatar from './Avatar'
import { UserProfile } from '../lib/types'
import { useSupabase } from '../lib/supabase'
import Image from 'next/image'

type Props = {
  profile: UserProfile | null
  primaryActionButton?: JSX.Element
}

export default function UserHeader({ profile, primaryActionButton }: Props) {
  const supabase = useSupabase()
  const handleSignOut = () => supabase.auth.signOut()

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-start space-x-5">
        <div className="flex-shrink-0">
          <div className="mt-1 ml-3 relative">
            <Avatar profile={profile} size="lg" />
            <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
          </div>
        </div>
        {/*
            Use vertical padding to simulate center alignment when both lines of text are one line,
            but preserve the same layout if the text wraps without making the image jump around.
          */}
        <div className="pt-1.5">
          {profile ? (
            <>
              <p className="text-sm font-medium text-gray-500">Welcome back,</p>
              <h1 className="text-2xl font-bold text-gray-900">{profile.preferredName}</h1>
            </>
          ) : (
            <>
              <h1 className="text-2xl font-bold text-gray-900">Welcome</h1>
              <p className="text-sm font-medium text-gray-500">
                Once your profile is setup, your membership will activate
              </p>
            </>
          )}
        </div>
      </div>
      {
        <div className="pt-1 hidden sm:block">
          <Image
            alt="isosisko talousklubi logo"
            className="rounded-2xl"
            src="/images/isosisko-talousklubi.png"
            height="72px"
            width="200px"
          />
        </div>
      }
      <div className="inline-flex sm:flex-row flex-col-reverse sm:p-0 p-2">
        {primaryActionButton}
        <Button className="sm:mr-4" type="reset" onClick={handleSignOut}>
          Sign out
        </Button>
      </div>
    </div>
  )
}
