import { cloneElement, useEffect } from 'react'
import { FormEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { UseFormRegister, UseFormSetValue } from 'react-hook-form'

import Button from './Button'
import Card from './Card'
import CardHeading from './CardHeading'

export type EditableCardChildProps<T> = Partial<{
  data: T | null
  register: UseFormRegister<T>
  setValue: UseFormSetValue<T>
}>

type Props<T> = {
  title?: string
  description?: string
  editableChild: JSX.Element
  viewableChild: JSX.Element
  data: T | null
  onSubmit: (data: T) => Promise<void>
}

export default function EditableCard<T>({ title, description, ...props }: Props<T>) {
  const [data, setData] = useState<T | null>(props.data)

  // Default to 'edit' mode, if no data is present initially.
  const [mode, setMode] = useState<'read-only' | 'edit'>(props.data === null ? 'edit' : 'read-only')
  const handleToggleMode = () => (mode === 'read-only' ? setMode('edit') : setMode('read-only'))

  // Synchronize local state with the data fetched by the parent component. This allows components that are still
  // fetching data to update the EditableCard with the results once they are processed, while still allowing
  // use of the EditableCard component for cases where the data does not exist yet and needs to be input manually.
  useEffect(() => {
    setData(props.data)
    setMode(props.data === null ? 'edit' : 'read-only')
  }, [props.data])

  const editButton = (
    <Button key="edit-button" type="button" onClick={handleToggleMode}>
      Edit
    </Button>
  )

  // First, call the onSubmit handler passed in from the parent
  // Then, simultaneously, set the local data to avoid sychronization issues
  // and toggle the edit mode of the card back to 'read-only'
  const onSubmit = async (submissionData: T) => {
    await props.onSubmit(submissionData)
    await Promise.all([setData(submissionData), handleToggleMode()])
  }

  // Cancelling the 'edit' just toggles the form back to 'read-only' mode
  // without resetting the unfinished the user input.
  const onCancel = (event: FormEvent) => {
    event.preventDefault()
    handleToggleMode()
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }, // TODO: Display errors in-line with form
  } = useForm<T>()

  switch (mode) {
    case 'read-only':
      return (
        <form>
          <Card>
            <CardHeading title={title} description={description} actionButtons={[editButton]} />
            {cloneElement(props.viewableChild, { ...props.viewableChild.props, data })}
          </Card>
        </form>
      )
    case 'edit':
      return (
        // @ts-ignore Mismatch between '(data: T) => Promise<void>' and 'SubmitHandler<T>' from react-hook-form.
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card
            primaryButton={<Button>Save</Button>}
            cancelButton={
              <Button type="reset" onClick={onCancel}>
                Cancel
              </Button>
            }
          >
            <CardHeading title={title} description={description} />
            {cloneElement(props.editableChild, { ...props.editableChild.props, data, register, setValue })}
          </Card>
        </form>
      )
  }
}
