import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'

type Props = React.PropsWithChildren<{}> &
  Pick<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'className' | 'type' | 'onClick'>

export default function Button({ children, type = 'submit', onClick, className }: Props) {
  let buttonStyle
  switch (type) {
    case 'submit':
    case 'button':
      buttonStyle =
        'inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-600 text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 col-start-2 sm:text-sm'
      break
    case 'reset':
      buttonStyle =
        'inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:mt-0 col-start-1 sm:text-sm'
      break
  }

  return (
    <button type={type} onClick={onClick} className={`${buttonStyle} ${className}`}>
      {children}
    </button>
  )
}
