import { useState } from 'react'
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps'
import ReactTooltip from 'react-tooltip'

import { MemberCoordinate, Point } from '../lib/types'
import LocationMarker from './LocationMarker'

const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json'

type Props = {
  memberCoordinates: Array<MemberCoordinate>
  onSelectLocationMarker: (point: Point) => void
  onSelectState: (state: string) => void
}

export default function UnitedStatesMap({ memberCoordinates, onSelectLocationMarker, onSelectState }: Props) {
  const [tooltip, setTooltip] = useState('')

  return (
    <div className="flex justify-center">
      <ComposableMap
        projection="geoAlbersUsa"
        style={{ height: 'calc(100vh - 82px)' /* TopNav is 82px */ }}
        data-tip=""
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                stroke="#FFF"
                geography={geo}
                // onMouseEnter={() => setTooltip(geo.properties.name)}
                // onMouseLeave={() => setTooltip('')}
                onClick={() => onSelectState(geo.properties.name)}
                style={{
                  default: {
                    fill: '#000',
                    outline: 'none',
                  },
                  hover: {
                    fill: '#fbcfe8',
                    outline: 'none',
                  },
                  pressed: {
                    fill: '#db2777',
                    outline: 'none',
                  },
                }}
              />
            ))
          }
        </Geographies>
        {memberCoordinates.map((location) => (
          <Marker
            key={location.name}
            coordinates={[location.point.longitude, location.point.latitude]}
            onClick={() =>
              onSelectLocationMarker({ longitude: location.point.longitude, latitude: location.point.latitude })
            }
            onMouseEnter={() => setTooltip(location.name)}
            onMouseLeave={() => setTooltip('')}
          >
            <LocationMarker type="diamond" size={24} color={location.hasAdmin ? '#D18EFC' : '#F56991'} />
          </Marker>
        ))}
      </ComposableMap>
      <ReactTooltip type="light">{tooltip}</ReactTooltip>
    </div>
  )
}
