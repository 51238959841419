import Image from 'next/image'
import { UserAvatar } from '../lib/types'
import { AvatarProps, calculateSize } from './Avatar'

type Props = { avatar: UserAvatar } & AvatarProps

export default function AvatarFacebook({ avatar, size }: Props) {
  return (
    <Image
      alt="facebook profile picture"
      src={`data:${avatar.mimeType};base64,${avatar.data}`}
      className="rounded-full"
      width={calculateSize(size)}
      height={calculateSize(size)}
    />
  )
}
