type Props = React.PropsWithChildren<{ primaryButton?: JSX.Element; cancelButton?: JSX.Element }>

export default function Card({ children, primaryButton, cancelButton }: Props) {
  return (
    <div className="mt-5 md:mt-0 md:col-span-2 mx-auto max-w-screen-lg">
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="md:px-12 px-4 py-5 bg-white space-y-6 sm:p-6">{children}</div>
        {primaryButton || cancelButton ? (
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 grid grid-cols-2 gap-3 grid-flow-row-dense">
            {primaryButton}
            {cancelButton}
          </div>
        ) : null}
      </div>
    </div>
  )
}
