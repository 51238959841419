type Props = {
  title?: string
  description?: string
  actionButtons?: Array<JSX.Element>
}

export default function CardHeading({ title, description, actionButtons = [] }: Props) {
  return (
    <div className="bg-white py-5">
      <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <div className="ml-4 mt-2">
          {title && <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>}
          {description && <p className="mt-1 text-sm text-gray-500">{description}</p>}
        </div>
        <div className="ml-4 mt-2 flex-shrink-0">{actionButtons}</div>
      </div>
    </div>
  )
}
