export default function DataList<T>({ data, humanize = true }: { data: Partial<T>; humanize?: boolean }) {
  return (
    <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
      <dl className="sm:divide-y sm:divide-gray-200">
        {data &&
          Object.entries(data).map(([label, value]) => (
            <div key={label} className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{humanize ? humanizeLabel(label) : label}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 whitespace-pre-line">{value as any}</dd>
            </div>
          ))}
      </dl>
    </div>
  )
}

const humanizeLabel = (label: string) => {
  switch (label) {
    case 'fullName':
      return 'Full name'
    case 'preferredName':
      return 'Preferred name'
    case 'about':
      return 'About'
    case 'currentLocationName':
      return 'Current Location'
    default:
      throw new Error(`Missing case for label "${label}". Please provide humanized language.`)
  }
}
