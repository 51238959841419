import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ArrowLeftIcon, XIcon } from '@heroicons/react/outline'

type Props = React.PropsWithChildren<{
  title?: string
  size?: 'small' | 'wide'
  onClose: () => void
  onBack?: () => void
}>

export default function SlideOver({ children, title, size = 'small', onClose, onBack }: Props) {
  const [open, setOpen] = useState(true)
  useEffect(() => {
    if (!open) onClose()
  }, [open, onClose])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className={`w-screen ${size === 'small' ? 'max-w-md' : 'max-w-2xl'}`}>
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="p-6">
                    <div className="flex items-start justify-between">
                      <div className="ml-3 h-7 flex items-center">
                        {onBack ? (
                          <button
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-pink-500"
                            onClick={onBack}
                          >
                            <span className="sr-only">Go back to list</span>
                            <ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        ) : null}
                      </div>
                      <Dialog.Title className="text-lg font-medium text-gray-900">{title}</Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-pink-500"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
