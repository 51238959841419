import React from 'react'
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete'
import useOnclickOutside from 'react-cool-onclickoutside'

import { FormInput } from '../components'
import { UserLocation } from '../lib/types'

type Props = {
  data: UserLocation | null
  onSelect: (location: UserLocation) => void
}

export default function FormLocationAutocomplete({ data: locationData, onSelect }: Props) {
  const {
    ready,
    value,
    suggestions: { status, data: suggestionData },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    defaultValue: locationData?.name,
    requestOptions: {
      componentRestrictions: { country: 'us' },
      types: ['(cities)'],
    },
    debounce: 600,
  })

  // Clear suggestions dropdown when the user clicks outside the component
  const ref = useOnclickOutside(() => clearSuggestions())

  const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
    const address = event.currentTarget.value
    setValue(address)
  }

  const handleSelect = async (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const address = event.currentTarget.textContent ?? ''
    // When user selects a place, we can replace the keyword without request data from API by setting the second parameter to "false"
    setValue(address, false)
    clearSuggestions()

    const results = await getGeocode({ address })
    if (results.length === 1) {
      const result = results[0]
      const state = result.address_components.find((component) =>
        component.types.includes('administrative_area_level_1'),
      )!.long_name
      const location = {
        name: result.formatted_address,
        state,
        point: {
          latitude: result.geometry.location.lat(),
          longitude: result.geometry.location.lng(),
        },
        metadata: result,
      }
      onSelect(location)
    }
  }

  const renderSuggestions = () =>
    suggestionData.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion

      return (
        <li key={place_id} onClick={handleSelect}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      )
    })

  return (
    <div ref={ref}>
      <FormInput
        label="Current Location"
        value={value}
        disabled={!ready}
        autoComplete="address-level3"
        placeholder="Austin, Texas"
        onChange={handleInput}
        required
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && <ul>{renderSuggestions()}</ul>}
    </div>
  )
}
