import { LocationMarkerIcon } from '@heroicons/react/solid'
import IconDiamond from './IconDiamond'

type Props = {
  type?: 'map-marker' | 'diamond'
  color?: string
  size?: number
}
export default function LocationMarker({ type = 'map-marker', color = '#F56991', size = 24 }: Props) {
  switch (type) {
    case 'map-marker':
      return <LocationMarkerIcon width={size} height={size} fill={color} transform="translate(-10, -22)" />
    case 'diamond':
      return <IconDiamond color={color} size={size} translate />
  }
}
